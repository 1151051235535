import {Component, OnInit} from '@angular/core';
import {HomeBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent extends HomeBaseComponent {
	id=0 ;
	storeid= this.confService.storeId.subscribe((data) =>{
		this.id = data
	});
showCart=this.id==12778?true:false

}
