import {Component} from '@angular/core';
import {FooterBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends FooterBaseComponent {
	id=0 ;
	storeid= this.confService.storeId.subscribe((data) =>{
		this.id = data
	});
showCart=this.id==12778?true:false

}
