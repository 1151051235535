<div class="page-head" *ngIf="breadcrumbs.length > 0">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let l = last">
          <a [routerLink]="breadcrumb.url.path" [queryParams]="breadcrumb.url.params">
            {{ breadcrumb.name }}
          </a>
        </li>
      </ol>
    </nav>
  </div><!--End Container-->
</div><!--End Page-head-->