import {Component} from '@angular/core';
import {VerifyPhoneBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-verify-phone',
	templateUrl: './verify-phone.component.html',
	styleUrls: ['./verify-phone.component.scss']
})
export class VerifyPhoneComponent extends VerifyPhoneBaseComponent {
}
