import {Component} from '@angular/core';
import {HeaderBaseComponent,StoreIdentity} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HeaderBaseComponent {
	id=0 ;
	storeid= this.confService.storeId.subscribe((data) =>{
		this.id = data
	});
  
  
	myStore
	storeSubscription = this.confService.store$.subscribe((store: StoreIdentity) => {
		this.myStore = store;
		console.log(this.myStore.isContactUsInsteadAddCart)
	})

	showCart=this.id==12778?true:false

}
