import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

	// Add global error handling for chunk load errors
	(window as any).onerror = function (message, source, lineno, colno, error) {
		if (error && error.message && error.message.includes('ChunkLoadError')) {
		  console.error('Chunk Load Error:', error);
		  alert('Failed to load module. Please refresh the page.');
		}
	  };
	  
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
