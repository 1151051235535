import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product/product.component';
import { NgxBbnZvendoModule } from '@bbndev/ngx-bbn-zvendo';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { OwlModule } from 'ngx-owl-carousel';
import { RouterModule } from '@angular/router';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
	declarations: [
		ProductComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		CarouselModule,
		OwlModule,
		NgxBbnZvendoModule
	],
	exports: [
		ProductComponent,
		CarouselModule,
		RatingModule,
		TabsModule,
		OwlModule
	]
})
export class SharedModule { }
