import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { OwlModule } from 'ngx-owl-carousel';
import { NouisliderModule } from 'ng2-nouislider';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './header/search/search.component';
import { SideMenuComponent } from './header/side-menu/side-menu.component';
import { CompareComponent } from './compare/compare.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NgxBbnZvendoModule } from '@bbndev/ngx-bbn-zvendo';
import { environment } from '../environments/environment';
import { FeatureProductComponent } from './feature-product/feature-product.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PopupComponent } from './popup/popup.component';
import { VerifyPhoneComponent } from './modules/accounts/verify-phone/verify-phone.component';
import { SharedModule } from './modules/shared/shared.module';
import { DecimalPipe } from '@angular/common';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		HomeComponent,
		FeatureProductComponent,
		SearchComponent,
		SideMenuComponent,
		CompareComponent,
		WishListComponent,
		BreadcrumbComponent,
		QuickViewComponent,
		ContactUsComponent,
		PopupComponent,
		VerifyPhoneComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule,
		AppRoutingModule,
		CarouselModule.forRoot(),
		AccordionModule.forRoot(),
		CollapseModule.forRoot(),
		ModalModule.forRoot(),
		RatingModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		OwlModule,
		NouisliderModule,
		ToastrModule.forRoot(),
		PaginationModule.forRoot(),
		BrowserAnimationsModule,
		NgSelectModule,
		SocialLoginModule,
		NgxBbnZvendoModule.forRoot(environment)
	],
	providers: [
		DecimalPipe,
		DynamicDialogConfig,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							localStorage.getItem('googleOauth')
						)
					},
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider(
							localStorage.getItem('FacebookAppId')
						)
					}
				],
				onError: (err) => {
					// eslint-disable-next-line no-console
					console.info(err);
				}
			} as SocialAuthServiceConfig,
		},
		Title,
		{ provide: 'env', useValue: environment }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
