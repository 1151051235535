<!-- If store is available -->
<ng-container *ngIf="!(notAvaliableStore$ | async) && !(storeIsExpired$ | async); else notAvailStore">
  <!-- Check If Store is Published -->
  <ng-container *ngIf="!(isUnderConstructionStore | async); else storeNotPublished">
    <div id="wrapper">
      <div *ngIf="(((storeIsActivated | async) === false) && ((storeEnabledTestOrder | async) === false)) ">
        <app-store-not-activated [ngClass]=" {'fixed': isScrolled} ">
          {{language['storeIsNotActivated']}}
        </app-store-not-activated>
      </div>
      <app-store-not-activated *ngIf="(storeEnabledTestOrder | async) "[ngClass]=" {'fixed': isScrolled} ">
        {{language['storeEnabledTestOrder'] || 'Store is now inactive and accept test orders'}}
      </app-store-not-activated>
      <!-- Header component -->
      <app-header [ngClass]="{'d-none': HideHeaderFooter}"></app-header>
      <!-- Breadcrumb component -->
      
      <main id="content">
        <app-breadcrumb [ngClass]="{'d-none': HideHeaderFooter}"></app-breadcrumb>
        <router-outlet></router-outlet>
      </main>
      <!-- Footer component -->
      <app-footer [ngClass]="{'d-none': HideHeaderFooter}"></app-footer>
      <!-- Model [Quick view] -->
      <div #staticModal="bs-modal" aria-labelledby="dialog-child-name" bsModal class="modal fade" role="dialog"
        tabindex="-1">
        <app-quick-view (myEvent)="closeQuickView()" *ngIf="isModalShown|async" [product]="quickView"></app-quick-view>
      </div>
      <app-base-user-location *ngIf="((openPopupModal$ | async) && (storeIsMultiLocations$ | async))"></app-base-user-location>
      <app-popup></app-popup>
    </div>
  </ng-container>
</ng-container>
<!-- If store is not available -->
<ng-template #notAvailStore>
  <main class="container">
    <app-store-not-available></app-store-not-available>
  </main>
</ng-template>

<!--If Store Is not Published-->
<ng-template #storeNotPublished>
  <main class="container">
    <app-store-not-published></app-store-not-published>
  </main>
</ng-template>
