<div class="bkg-home">
  <div class="sliderStyle" [ngClass]="{removeMinHeight: !slides.length}">
    <owl-carousel #slider [carouselClasses]="['owl-theme']" [items]="slides"
      [options]="changeCarouselOptions({margin: 0})" [hidden]="!sliderLoaded">
      <div (click)="goToSliderLink(slide)" class="item" *ngFor="let slide of slides">
        <img [src]="slide.picture" alt="carousel" (load)="sliderLoaded = true" [hidden]="!sliderLoaded"
          [errorImage]="defaultImage" class="animate-fade">
      </div>
    </owl-carousel>
    <div class='spinner text-center' *ngIf="!sliderLoaded && slides.length">
      <app-spinner></app-spinner>
    </div>
  </div>


  
  <section class="shop" *ngIf="(featuredTaxons.length > 0) && showCart">
    <div class="Container">
      <div class="all-shop">
        <div class="row">
          <!-- Main categories -->
          <!-- First Column -->
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="left-shop">
              <div 
                class="dress-image" 
                style="background-image: 'url(../../assets/images/Our-Brands2.jpg)' ">
              </div>
              <div class="dresses">
                <a 
                  class="shop-now-open link--body" 
                  routerLink="/{{language['LANGUAGE_CODE']}}/page/Our_Brands">
                  Check
                </a>
                <p>Our Brands</p>
              </div>
            </div>
          </div>
          <!-- Second Column -->
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="left-shop">
              <div 
                class="dress-image" 
                style="background-image: 'url(../../assets/images/Our-Showrooms.jpg)'">
              </div>
              <div class="dresses">
                <a 
                  class="shop-now-open link--body" 
                  routerLink="/{{language['LANGUAGE_CODE']}}/page/contact">
                  Check
                </a>
                <p>Our Showrooms</p>
              </div>
            </div>
          </div>
          <!-- End main categories -->
        </div>
      </div>
    </div>
  </section>
  
  

  <section class="shop" *ngIf="(featuredTaxons.length > 0) && !showCart">
    <div class="Container">
      <div class="all-shop">
        <div class="row">
          <!--main categories-->
          <div *ngFor="let taxon of featuredTaxons | slice: 0: 2" class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div class="left-shop">
              <div class="dress-image" [lazyLoad]="imageUrl + taxon.images[0]?.path"
                [ngStyle]="{ 'background-image': 'url(' + imageUrl + taxon.images[0]?.path + ')'}"></div>
              <div class="dresses">
                <a class="shop-now-open link--body" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
                  [queryParams]="{taxons: taxon.code}">{{language['shopNow']}}</a>

                <p>{{taxon | translate: 'name'}}</p>
              </div>
            </div>
          </div>
          <!--main categories-->
        </div>
      </div>
    </div>
  </section>




  <section *ngIf="homeBanner$ | async as homeBanner">
    <div class="DressCode-Middle-Banner">
      <img [lazyLoad]="homeBanner" class="img-fluid" alt="banner" [errorImage]="defaultImage">
    </div>
  </section>
  <section class="main-accessories">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="register" *ngIf="!showCart">
            <div class="col-md-12 form">
              <div class="col-md-12">
                <label>
                  {{language['Subscribe_to_newsletter']}}
                </label>
              </div>
              <div class="col-md-12">
                <form id="footer-newsletter-form" class="subscribe--newsletter" [formGroup]="subscribeForm">
                  <input id="newsletter-email" type="text" placeholder="{{language['enter_your_email']}}"
                    formControlName="email">
                  <button class="ui button btn btn--newsletter" aria-hidden="true" (click)="getRecaptcha()"
                    [disabled]="subscribeForm.invalid">{{language['subscribe']}} </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-base-recaptcha *ngIf="recaptchaData && recaptchaData.img &&  recaptchaData.id && recaptchaData.email"
    recaptchaEmail="{{recaptchaData.email}}" recaptchaId="{{recaptchaData.id}}"
    recaptchaImg="{{recaptchaData.img}}"></app-base-recaptcha>

</div>