<form #searchForm class="search-form">

  <input
    #search
    class="form-control"
    name="search-term"
    autocomplete="off"
    placeholder="{{language['search']}}"
    (keyup)="changed($event.target.value)"
    (keyup.enter)="serchResult($event.target.value)"
    (focusout)="focusOutFunction()"
  >
  <span class="border-effect"></span>
  <ul id="completionList" *ngIf="autoComplete">
    <li
      (click)="autoComplete = false"
      [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product', product['slug']]"
      *ngFor="let product of completeToThose | slice:0:[3]">
      {{product['name']}}
    </li>
    <li *ngIf="showNoAvaliableStatus" (click)="autoComplete = false">
      <span> {{language['noProductsAvaliable']}} </span>
    </li>
    <li *ngIf="showAllProducts">
      <a
        [routerLink]="['/', language['LANGUAGE_CODE'], 'collection']"
        [queryParams]="{q: search.value, page: 1}"
        (click)="autoComplete = false"
      >
        {{language['showAllProducts']}}
      </a>
    </li>
  </ul> 
</form>
