<div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <!-- Close the model -->
      <div class="modal-header p-0">
        <button (click)="myEvent.emit();close()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- Model content -->
      <div class="modal-body p-0">
        <div class="container-fluid">
          <!--Spinner loading-->
          <div *ngIf="!selectedVariant">
            <app-inner-loading></app-inner-loading>
          </div>
          <!-- Quick view content -->
          <div class="quickview_product" *ngIf="selectedVariant">
            <div class="row sm-gutters">
              <!--Image of product-->
              <div class="col-md-5 d-flex justify-content-center align-items-center">
                <!-- <div class="spinner widget-img d-inline-flex justify-content-center align-items-center"
                  *ngIf="!imgIsLoaded">
                  <app-spinner></app-spinner>
                </div> -->
                <img class="quickview_product-img responsive-image-container" [lazyLoad]="src" (load)="imgIsLoaded = true" [errorImage]="defaultImage"
                  [alt]="product.name">
              </div>
  
              <!--Product info-->
              <div class="col-md-7">
                <div class="quickview_product-content">
                  <!-- Product name -->
                  <div>
                    <h2 class="modal-title" id="dialog-child-name">
                      <a class="product-title" (click)="myEvent.emit()"
                        [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product',  product | translate: 'slug']">
                        {{product | translate : 'name'}}
                      </a>
                    </h2>
                  </div>
                  <div class="rating--icons"
                    *ngIf="(reviewsFeature$ | async) || (wishListFeature$ | async) || (compareFeature$ | async)">
                    <!-- Rating product -->
                    <div class="product-rating" *ngIf="reviewsFeature$ | async">
                      <rating [(ngModel)]="rate" [max]="maxRate" [readonly]="true">
                      </rating>
                    </div>
  
                    <div>
                      <!-- Add to wishlist btn -->
                      <button *ngIf="wishListFeature$ | async" [ngClass]="{'active': fav | async}" class="icon-btn"
                        (click)="toggleWishList()">
                        <i class="fa fa-heart icon--hover" aria-hidden="true"></i>
                      </button>
                      <!-- Add to compare btn -->
                      <button *ngIf="compareFeature$ | async" [ngClass]="{ 'active' : compare | async}" class="icon-btn"
                        (click)="toggleCompare()">
                        <i class="fa fa-random icon--hover" aria-hidden="true"></i>
                      </button>
                    </div>
  
                  </div>
                  <!-- Product description -->
                  <div class="description--code">
                    <p class="product-code" *ngIf="selectedVariant.sku">
                      <strong>SKU: </strong>{{ selectedVariant?.sku }}
                    </p>
                    <!-- Short description for the product -->
                    <p class="product-short-desc" [innerHTML]="product | translate: 'short_description'"></p>
                  </div>
                  <!-- Actions to the product [quantity, options] -->
                  <div class="product-actions">
                    <div class="product-options" *ngFor="let option of product.options">
                      <div class="form-group">
                        <div class="row pt-3">
                          <div class="col-4 d-flex align-items-center">
                            <label class="m-0">{{option | translate: 'name'}}</label>
                          </div>
                          <div class="col-8">
                            <div *ngIf="!option.view_as_thumbnail; else thumbnails">
  
                              <select class="form-control" [(ngModel)]="options[option.code]"
                                (change)="optionChanged(option.code, $event.target.value)"
                                title="{{option | translate: 'name'}}">
                                <option value="" disabled="true">{{language['selectA']}}
                                  {{option | translate: 'name'}}</option>
                                <option *ngFor="let variant of option.option_values" [value]="variant.code">
                                  {{variant | translate: 'name'}}
                                </option>
                              </select>
                            </div>
                            <ng-template #thumbnails>
                              <div class="check-radio-item inline with-img" *ngFor="let variant of option.option_values">
                                <input type="radio" [name]="option.name" [value]="variant.code" [id]="variant.code"
                                  (click)="optionChanged(option.code, variant.code)" onError="this.src=defaultImage;">
                                <label [for]="variant.code" class="tooltip">
                                  <img [lazyLoad]="imageUrl+variant.thumbnail" [alt]="variant.name"  [errorImage]="defaultImage"/>
                                  <span class="tooltiptext">{{variant.name}}</span>
                                </label>
                              </div>
  
                            </ng-template>
                          </div>
                        </div>
                      </div>
                      <!--End Form-group-->
                    </div>
                    <!--End Options-->
                    <div class="product-price row m-0" *ngIf="!product.hidePrice">
                      <div class="col-3 pl-0">
                        <span class="text-uppercase">{{language['price']}}</span>
                      </div>
                      <div class="current--original col-9 p-0 text-center">
                        <div *ngIf="variantUndefined; else variantAvailable">{{language['itemNotAvailable']}}</div>
                        <div #variantAvailable>
                          <div *ngIf="product.productType">
                            <span class="current-price pr-3">
                              {{selectedVariant.prices[0].price / 100 | number }} {{currencyCode}}
                            </span>
                            <span class="original-price"
                              *ngIf="product.discounted && selectedVariant.prices[0].original_price > 0">
                              {{selectedVariant.prices[0].original_price / 100 | number }} {{currencyCode}}
                            </span>
                          </div>
                          <div *ngIf="!product.productType">
                            <span class="current-price pr-3">
                              {{selectedVariant.prices[0].price / 100 | number }} {{currencyCode}}
                            </span>
                            <span class="original-price"
                              *ngIf="product.discounted && selectedVariant.prices[0].original_price > 0">
                              {{selectedVariant.prices[0].original_price / 100 | number }} {{currencyCode}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--End Product-price-->
                  </div>
                </div>
                <div class="btn--quantity pt-4 row m-0" *ngIf="!product.hidePrice">
                  <div class="form-group col-4 ">
                    <input class="form-control" numbersOnly type="number" [(ngModel)]="quantity"
                      (change)="quantityChanged()" min="1" [max]="max" [disabled]="disableQuantity" title="quantity" *ngIf="!(myStore.isContactUsInsteadAddCart)">
                  </div>
                  <!--End Form-group-->
                  <div class="form-group col-8 p-0">
                    <button class="custom-btn w-100" [ngClass]="{'no-items-remaining':max.value==0}" (click)="addToCart()"
                      [disabled]="!canAddToCart || cartService.addedToCart.getValue() == false || max.value==0" *ngIf="!(myStore.isContactUsInsteadAddCart)">
                      <!-- {{canAddToCart ? language['addToCart'] : language['outOfStock']}} -->
                      {{language['addToCart']}}
                      </button>
                    <button class="custom-button w-100" [ngClass]="{'no-items-remaining':max.value==0}" (click)="SwtichToContactUs()" 
                    aria-label="contactUS" *ngIf="(myStore.isContactUsInsteadAddCart)">
                      <!-- {{canAddToCart ? language['addToCart'] : language['outOfStock']}} -->
                      {{ language['LANGUAGE_CODE'] === 'en_US' ? " contact US" : "
                      تواصل معنا" }}
                      </button>
                     
                  </div>
                  <!--End Form-group-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  