export const environment = {
	production: true,
	apiUrl: 'API_DEFAULT_URL',
	imageUrl: 'IMAGE_DEFAULT_URL',
	apiVersion: 'v3',
	taxonApiVersion: 'v4',
	sliderApiVersion: 'v4',
	maestroUrl: 'https://register.zvendo.com/api/v4/',
	languageBaseUrl: 'https://register.zvendo.com/translations/',
	defaultImage: 'https://register.zvendo.com/images/store-empty-product-category.jpeg',
	localStore: 'swish.zvendostore.com'
};
