import {Component} from '@angular/core';
import {ContactUsBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends ContactUsBaseComponent {
}
