import {Component} from '@angular/core';
import {WishListBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-wish-list',
	templateUrl: './wish-list.component.html',
	styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent extends WishListBaseComponent {
}
