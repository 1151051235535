import {Component} from '@angular/core';
import {BreadcrumbBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BreadcrumbBaseComponent {
}
