import {Component} from '@angular/core';
import {QuickViewBaseComponent, StoreIdentity} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-quick-view',
	templateUrl: './quick-view.component.html',
	styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent extends QuickViewBaseComponent {
	myStore
		storeSubscription = this.confService.store$.subscribe((store: StoreIdentity) => {
			this.myStore = store;
			console.log(this.myStore.isContactUsInsteadAddCart)
		})
		SwtichToContactUs() {
			
			this.quickViewService.close();
			const ChoicesObjects: any[] = []
			const noEmptyStrings = ChoicesObjects.push({ name: this.product.name });
			console.log(noEmptyStrings)
	
	
			this.router!!.navigate(['/contact-us/'], {
				queryParams: { OrderData: JSON.stringify(ChoicesObjects) },
			});
			let ele:any=document.querySelector('.close')
			ele.click()
		}
}

