import {Component} from '@angular/core';
import {PopupBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-popup',
	templateUrl: './popup.component.html',
	styleUrls: ['./popup.component.scss']
})
export class PopupComponent extends PopupBaseComponent {
}
